import { ExplorationFlagsType } from './flags';
import { useReportAdobeFeatureFlags } from '@analytics/react';
import { datadogRum } from '@datadog/browser-rum';
import { logger } from '@thrivent-web/logging-utils';
import { useEffect } from 'react';

// --------------------------------------------------------------------------

export const useHandleInitialFeatureFlags = (flags: ExplorationFlagsType) => {
  const reportAdobeFeatureFlags = useReportAdobeFeatureFlags();
  useEffect(() => {
    try {
      if (typeof window !== 'undefined') {
        reportAdobeFeatureFlags(flags);
        Object.entries(flags).map(([key, value]) =>
          datadogRum.addFeatureFlagEvaluation(key, value),
        );
      }
    } catch (err) {
      logger.warn(
        'There was an error dispatching the flag analytics event',
        err,
      );
    }
  }, [flags, reportAdobeFeatureFlags]);
};
