import { IconProps } from '../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const SupportPerson = ({
  height = 50,
  width = 50,
  colorPrimary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryMedium;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M28.96 23.44C33.6434 23.44 37.44 19.6434 37.44 14.96C37.44 10.2766 33.6434 6.47998 28.96 6.47998C24.2766 6.47998 20.48 10.2766 20.48 14.96C20.48 19.6434 24.2766 23.44 28.96 23.44Z"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.76 55.7499C13.21 47.7199 14.2 37.3699 14.2 37.3699C14.2 37.3699 15.32 30.8599 28.26 30.8599C28.26 30.8599 41.73 29.8699 43.3 37.4399L44.75 55.7399"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.49 10.77V19.14C40.49 19.14 44.75 19.5 44.75 15.14C44.75 10.41 40.49 10.77 40.49 10.77Z"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.01 19.1399V10.7699C17.01 10.7699 12.75 10.4099 12.75 14.7699C12.75 19.4999 17.01 19.1399 17.01 19.1399Z"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.49 10.77C40.49 10.77 39.97 1.75 28.96 1.75C17.95 1.75 17.01 10.77 17.01 10.77"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.49 19.1399C40.49 19.1399 38.13 23.4099 35.5 24.9699"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SupportPerson;
