import { IconProps } from '../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const SheetCalculator = ({
  height = 50,
  width = 50,
  colorPrimary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryMedium;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 46 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Group 634102">
        <path
          d="M32.8011 6.9341V1.00586H26.3826"
          stroke={primary}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.3911 1H1V37H32.8034V34.1391"
          stroke={primary}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.7456 16.366L32.404 13.3459L42.1334 15.2925L41.2411 19.3862L31.5117 17.4396L31.7456 16.3719V16.366Z"
          stroke={primary}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.3789 23.5098L40.0805 23.6514"
          stroke={primary}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.4648 22.9258L37.1664 23.0674"
          stroke={primary}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.5469 22.3418L34.2484 22.4834"
          stroke={primary}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.6289 21.7576L31.3305 21.8992"
          stroke={primary}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.6328 26.9609L39.3344 27.1026"
          stroke={primary}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.7148 26.375L36.4164 26.5166"
          stroke={primary}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.7969 25.793L33.4984 25.9346"
          stroke={primary}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.8789 25.207L30.5805 25.3487"
          stroke={primary}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.2568 31.5549C41.1461 32.0622 40.623 32.3925 40.0937 32.2863L27.1705 29.6968C26.6413 29.5906 26.2967 29.0892 26.4074 28.5819L30.2536 10.9388C30.3644 10.4315 30.8875 10.1012 31.4167 10.2074L44.3399 12.7969C44.8692 12.9031 45.2138 13.4045 45.103 13.9118L41.2568 31.5549Z"
          stroke={primary}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.707 18.5195H23.0579"
          stroke={primary}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.45312 18.6788L8.05929 20.0355L9.32699 16.1719"
          stroke={primary}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.707 27.4082H23.0579"
          stroke={primary}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.45312 27.7257L8.05929 29.0824L9.32699 25.2188"
          stroke={primary}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.707 9.44135H23.0579"
          stroke={primary}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.45312 9.63001L8.05929 10.9867L9.32699 7.12305"
          stroke={primary}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default SheetCalculator;
