import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const CheckCircle = ({
  height = 65,
  width = 65,
  colorPrimary,
  colorSecondary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryHeavy;
  const secondary =
    colorSecondary ?? midwestColors.semanticColorSentimentInfoMedium;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 65"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M62.0411 32.913C62.0411 48.9802 48.9667 62.0547 32.8995 62.0547C16.8323 62.0547 3.7612 48.9802 3.7612 32.913C3.7612 16.8458 16.8323 3.77474 32.8995 3.77474C42.6529 3.77474 51.3026 8.59219 56.594 15.9724L51.4786 21.0878C47.5651 14.9568 40.6995 10.8841 32.8995 10.8841C20.7526 10.8841 10.8706 20.7661 10.8706 32.913C10.8706 45.0599 20.7526 54.9453 32.8995 54.9453C45.0464 54.9453 54.9318 45.0599 54.9318 32.913C54.9318 28.9385 53.8721 25.2044 52.0203 21.9815L57.1831 16.8188C60.2503 21.4331 62.0411 26.9682 62.0411 32.913Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M62.2544 11.7474L52.0203 21.9815L32.3714 41.6305C32.2766 41.7253 32.1445 41.7794 32.0125 41.7794H31.982C31.8365 41.7693 31.7044 41.7016 31.613 41.5865L22.9497 30.6143C22.7771 30.3943 22.8143 30.076 23.0344 29.9C23.2544 29.7273 23.576 29.7646 23.7487 29.9846L32.0565 40.5099L51.4786 21.0878L56.594 15.9724L61.5367 11.0297C61.7331 10.8299 62.0547 10.8299 62.2544 11.0297C62.4508 11.226 62.4508 11.5477 62.2544 11.7474Z"
        fill={primary}
        className="fill-primary"
      />
    </svg>
  );
};

export default CheckCircle;
