import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const ArrowLeft = ({
  height = 16,
  width = 16,
  colorPrimary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryStrong;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      className={className}
      style={{ transform: 'scale(-1,1)' }}
    >
      <path
        d="M3.33317 8.66657H10.7798L7.5265 11.9199C7.2665 12.1799 7.2665 12.6066 7.5265 12.8666C7.7865 13.1266 8.2065 13.1266 8.4665 12.8666L12.8598 8.47323C13.1198 8.21323 13.1198 7.79323 12.8598 7.53323L8.47317 3.13323C8.21317 2.87323 7.79317 2.87323 7.53317 3.13323C7.27317 3.39323 7.27317 3.81323 7.53317 4.07323L10.7798 7.33323H3.33317C2.9665 7.33323 2.6665 7.63323 2.6665 7.9999C2.6665 8.36657 2.9665 8.66657 3.33317 8.66657Z"
        fill={primary}
        className="fill-primary"
      />
    </svg>
  );
};

export default ArrowLeft;
