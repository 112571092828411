import { IconProps } from '../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const Devices = ({
  height = 50,
  width = 50,
  colorPrimary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryMedium;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 55.5 43.63"
      className={className}
    >
      <g strokeWidth="1.5px" fill="none" stroke={primary}>
        <polyline points="44.1 41.42 30.77 41.42 30.77 15.88 48.41 15.88 48.41 19.95" />
        <line x1="43.54" y1="36.12" x2="31.01" y2="36.12" />
        <circle cx="39.59" cy="38.57" r=".92" />
        <rect x="43.66" y="19.95" width="11.09" height="21.48" />
        <line x1="43.82" y1="22.74" x2="54.6" y2="22.74" />
        <line x1="43.82" y1="36.58" x2="54.6" y2="36.58" />
        <circle cx="49.22" cy="39.03" r=".77" />
        <path d="M26.91,33.64H.8s-.84,4.76,4.18,4.76h22.33" />
        <polyline points="3.77 33.05 3.77 .75 52.46 .75 52.46 15.21" />
      </g>
    </svg>
  );
};

export default Devices;
