import { IconProps } from '../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const PeopleChatting = ({
  height = 50,
  width = 50,
  colorPrimary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryMedium;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 55.5 43.63"
      className={className}
    >
      <g strokeWidth="1.5px" fill="none" stroke={primary}>
        <polyline points="18.48 12.41 16.28 12.41 12.6 15.77 12.6 .75 34.42 .75 34.42 6.11" />
        <polygon points="38.34 8.6 38.34 23.62 34.65 20.26 20.45 20.26 20.45 8.6 38.34 8.6" />
        <circle cx="47.69" cy="24.63" r="3.79" />
        <path d="M40.44,42.88c.2-3.59.64-8.22.64-8.22,0,0,.5-2.91,6.29-2.91,0,0,6.03-.44,6.73,2.95l.65,8.19" />
        <circle cx="8" cy="24.63" r="3.79" />
        <path d="M.75,42.88c.2-3.59.64-8.22.64-8.22,0,0,.5-2.91,6.29-2.91,0,0,6.03-.44,6.73,2.95l.65,8.19" />
        <line x1="24.74" y1="14.49" x2="24.75" y2="14.49" />
        <line x1="29.39" y1="14.49" x2="29.4" y2="14.49" />
        <line x1="34.04" y1="14.49" x2="34.05" y2="14.49" />
      </g>
    </svg>
  );
};

export default PeopleChatting;
