import { breakpoints } from '@thrivent/midwest-shared';
import styled, { css } from 'styled-components';
import {
  ColorProps,
  LayoutProps,
  TypographyProps,
  color,
  layout,
  typography,
} from 'styled-system';

const ALLOWED_HTML_ELEMENTS = [
  'span',
  'div',
  'h1',
  'h2',
  'h3',
  'h4',
  'p',
  'label',
  'small',
  'figcaption',
] as const;

export type TextProps = {
  uppercase?: boolean;
  color?: string;
  as?: typeof ALLOWED_HTML_ELEMENTS;
} & TypographyProps &
  ColorProps &
  LayoutProps;

export interface TypographyToken {
  font: string;
  rem: string;
  weight: number;
  lineHeight: string;
}

export interface ResponsiveTypographyTokens {
  name: string;
  mobileName?: string;
  tabletName?: string;
  desktopName?: string;
  mobile: TypographyToken;
  tablet?: TypographyToken;
  desktop?: TypographyToken;
}

export const BaseTypography = styled.div<TextProps>`
  ${(p) =>
    p.uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${color}
  ${typography}
  ${layout}
`;

export const createTypography = (tokens: ResponsiveTypographyTokens) => css`
  font-family: ${tokens.mobile.font};
  font-size: ${tokens.mobile.rem};
  font-weight: ${tokens.mobile.weight};
  line-height: ${tokens.mobile.lineHeight};

  ${tokens?.tablet &&
  css`
    ${breakpoints.tablet} {
      font-family: ${tokens.tablet.font};
      font-size: ${tokens.tablet.rem};
      font-weight: ${tokens.tablet.weight};
      line-height: ${tokens.tablet.lineHeight};
    }
  `}
  ${tokens?.desktop &&
  css`
    ${breakpoints.desktop} {
      font-family: ${tokens.desktop.font};
      font-size: ${tokens.desktop.rem};
      font-weight: ${tokens.desktop.weight};
      line-height: ${tokens.desktop.lineHeight};
    }
  `};
`;
