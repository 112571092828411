import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const BeachChair = ({
  height = 50,
  width = 50,
  colorPrimary,
  colorSecondary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryHeavy;
  const secondary = colorSecondary ?? midwestColors.identityBrandTertiary;
  return (
    <svg
      width={height}
      height={width}
      className={className}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.5625 51.6058H10.2231C10.2231 51.6058 14.0678 46.428 22.3563 43.9727L22.4519 44.7827C22.4772 44.9964 22.66 45.1539 22.871 45.1539C22.8878 45.1539 22.9047 45.1539 22.9216 45.1511C23.1522 45.123 23.3181 44.9148 23.29 44.6842L23.1775 43.742C24.325 43.4355 25.5513 43.1852 26.8591 43.008V44.732C26.8591 44.9655 27.0475 45.1539 27.281 45.1539C27.5144 45.1539 27.7028 44.9655 27.7028 44.732V42.9067C28.87 42.7773 30.0991 42.707 31.3928 42.707C35.021 42.707 38.1513 43.2583 40.8119 44.0852V44.732C40.8119 44.9655 41.0003 45.1539 41.2338 45.1539C41.4672 45.1539 41.6556 44.9655 41.6556 44.732V44.3636C49.1003 46.9398 52.5625 51.6058 52.5625 51.6058Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M26.5551 15.6144L19.9176 16.4075L19.0795 16.506L12.0117 17.3497C11.6207 14.3094 11.5504 9.23004 14.1829 4.46848C15.3811 4.08035 16.6495 3.80191 17.9686 3.64441C18.7842 3.54879 19.5914 3.50098 20.3845 3.50098C20.8879 3.50098 21.3857 3.52066 21.8807 3.55723C23.9339 5.94223 26.0545 9.76441 26.5551 15.6144Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M39.4808 28.4698V32.0107H29.0352V28.4698C29.0352 28.4304 29.0352 28.3939 29.038 28.3545H39.478C39.4808 28.3939 39.4808 28.4304 39.4808 28.4698Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M39.4808 35.0342H29.0352V38.4345H39.4808V35.0342Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M41.8702 38.4357H41.6565V35.7891H42.3821C42.6155 35.7891 42.804 35.6007 42.804 35.3673C42.804 35.1338 42.6155 34.9454 42.3821 34.9454H40.3262V28.471C40.3262 26.0832 38.3827 24.1426 35.9977 24.1426H32.5187C30.1337 24.1426 28.193 26.0832 28.193 28.471V34.9454H26.1343C25.9008 34.9454 25.7124 35.1338 25.7124 35.3673C25.7124 35.6007 25.9008 35.7891 26.1343 35.7891H26.8599V38.4357H26.649C26.1315 38.4357 25.7124 38.8548 25.7124 39.3695V41.057C25.7124 41.5716 26.1315 41.9907 26.649 41.9907H26.8599V44.7329C26.8599 44.9663 27.0483 45.1548 27.2818 45.1548C27.5152 45.1548 27.7037 44.9663 27.7037 44.7329V41.9907H40.8127V44.7329C40.8127 44.9663 41.0012 45.1548 41.2346 45.1548C41.468 45.1548 41.6565 44.9663 41.6565 44.7329V41.9907H41.8702C42.3849 41.9907 42.804 41.5716 42.804 41.057V39.3695C42.804 38.8548 42.3849 38.4357 41.8702 38.4357ZM40.3262 35.7891H40.8127V38.4357H40.3262V35.7891ZM29.0368 28.471C29.0368 28.4316 29.0368 28.3951 29.0396 28.3557C29.0987 26.4882 30.6371 24.9863 32.5187 24.9863H35.9977C37.8793 24.9863 39.4205 26.4882 39.4796 28.3557C39.4824 28.3951 39.4824 28.4316 39.4824 28.471V38.4357H29.0368V28.471ZM27.7037 35.7891H28.193V38.4357H27.7037V35.7891ZM41.9602 41.057C41.9602 41.1076 41.918 41.147 41.8702 41.147H26.649C26.5983 41.147 26.5562 41.1076 26.5562 41.057V39.3695C26.5562 39.3188 26.5983 39.2795 26.649 39.2795H41.8702C41.918 39.2795 41.9602 39.3188 41.9602 39.3695V41.057Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M36.796 15.243L36.7453 14.824C35.9241 7.95023 29.1853 2.93273 21.0572 2.66836C20.0166 2.63461 18.9507 2.67679 17.8707 2.80617C16.8216 2.92992 15.8063 3.12961 14.8275 3.39679C6.94409 5.54273 1.52722 12.0284 2.35128 18.9274L2.40191 19.3465L12.1388 18.1849L19.1785 17.344L19.4147 19.3099L22.3566 43.9727L22.4522 44.7827C22.4775 44.9965 22.6603 45.154 22.8713 45.154C22.8882 45.154 22.905 45.154 22.9219 45.1512C23.1525 45.123 23.3185 44.9149 23.2903 44.6843L23.1778 43.7421L20.2528 19.2115L20.0166 17.2455L26.6147 16.458L36.796 15.243ZM19.9182 16.4074L19.08 16.5059L12.0122 17.3496L3.14722 18.4071C2.66066 12.3349 7.32941 6.67054 14.1835 4.46836C15.3816 4.08023 16.65 3.80179 17.9691 3.64429C18.7847 3.54867 19.5919 3.50086 20.385 3.50086C20.8885 3.50086 21.3863 3.52054 21.8813 3.55711C29.0419 4.09992 34.8947 8.50148 35.851 14.5062L26.5557 15.6143L19.9182 16.4074Z"
        fill={primary}
        className="fill-primary"
      />
    </svg>
  );
};

export default BeachChair;
