import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const PeopleWithHeart = ({
  height = 65,
  width = 65,
  colorPrimary,
  colorSecondary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryHeavy;
  const secondary = colorSecondary ?? midwestColors.identityBrandTertiary;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 65"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M33.0228 8.14292C36.1186 3.74768 41.1578 6.76452 40.8326 11.4074C40.5395 15.6293 33.0228 20.0245 33.0228 20.0245C33.0228 20.0245 25.506 15.6293 25.2129 11.4074C24.8877 6.76039 29.9269 3.74355 33.0228 8.14292Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M33.0228 8.14292C36.1186 3.74768 41.1578 6.76452 40.8326 11.4074C40.5395 15.6293 33.0228 20.0245 33.0228 20.0245C33.0228 20.0245 25.506 15.6293 25.2129 11.4074C24.8877 6.76039 29.9269 3.74355 33.0228 8.14292Z"
        stroke={secondary}
        className="stroke-secondary"
        fill="none"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M26.4535 42.2236C23.8957 39.0788 19.8281 37.3248 15.5839 37.3248C8.54501 37.3248 2 42.9458 2 49.8833V58.9998H20.2819V52.0582C20.2819 52.0582 20.2257 44.9763 26.4535 42.2236Z"
        stroke={primary}
        fill="none"
        className="stroke-primary"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M15.6882 33.9365C20.1412 33.9365 23.7511 30.2264 23.7511 25.6496C23.7511 21.0728 20.1412 17.3626 15.6882 17.3626C11.2353 17.3626 7.62541 21.0728 7.62541 25.6496C7.62541 30.2264 11.2353 33.9365 15.6882 33.9365Z"
        stroke={primary}
        fill="none"
        className="stroke-primary"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M41.1656 43.2347C43.4905 40.7089 46.9316 39.3924 50.5093 39.3924C56.8736 39.3924 62.7963 44.4769 62.7963 50.7499V58.9956H45.5945V51.2658C45.5945 51.2658 45.4741 46.4991 41.1656 43.2305V43.2347Z"
        stroke={primary}
        fill="none"
        className="stroke-primary"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M57.8971 28.8315C57.8971 32.9709 54.6326 36.3303 50.6012 36.3303C46.5698 36.3303 43.3053 32.975 43.3053 28.8315C43.3053 24.688 46.5698 21.3328 50.6012 21.3328C54.6326 21.3328 57.8971 24.688 57.8971 28.8315Z"
        stroke={primary}
        fill="none"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M43.0807 59V52.1987C43.0807 47.0235 38.1981 42.8263 32.946 42.8263C27.6939 42.8263 22.8113 47.0235 22.8113 52.1987V59H43.0848H43.0807Z"
        stroke={primary}
        fill="none"
        className="stroke-primary"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        d="M39.041 34.11C39.041 37.5271 36.3467 40.2963 33.022 40.2963C29.6973 40.2963 27.003 37.5271 27.003 34.11C27.003 30.6928 29.6973 27.9236 33.022 27.9236C36.3467 27.9236 39.041 30.6928 39.041 34.11Z"
        stroke={primary}
        fill="none"
        className="stroke-primary"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default PeopleWithHeart;
