import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const BrandRefreshShield = ({
  height = 50,
  width = 50,
  colorPrimary,
  colorSecondary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryHeavy;
  const secondary = colorSecondary ?? midwestColors.identityBrandTertiary;
  return (
    <svg
      width={height}
      height={width}
      className={className}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3396 10.8116V30.2122C10.3424 30.2403 10.4352 33.9247 12.584 38.3066C14.474 42.1625 18.3805 47.3375 26.3118 50.5494L26.849 50.7632L27.6899 51.0978V51.9725L27.1274 51.7672C18.1668 48.4991 13.8496 42.8263 11.8077 38.6413C9.58866 34.0963 9.49866 30.3753 9.49585 30.2207V10.2491L27.6899 2.68066V3.59754L26.849 3.94629L26.4243 4.12348L10.3396 10.8116Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M27.6885 2.68066V2.68441H27.6922L27.6885 2.68066Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M27.6913 2.68066V3.59473L27.6885 3.59754V2.68066H27.6913Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M27.6913 51.0977V51.9752L27.6885 51.9723V51.0977H27.6913Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M45.8862 10.2491V30.2122C45.8834 30.3753 45.7934 34.0963 43.5771 38.6413C41.5325 42.8263 37.2181 48.4991 28.2546 51.7672L27.6921 51.9725V2.68066L45.8862 10.2491Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M27.6913 3.59473V51.0979H27.6885V3.59754L27.6913 3.59473Z"
        fill={secondary}
        className="fill-secondary"
      />
    </svg>
  );
};

export default BrandRefreshShield;
