'use client';

import { ExplorationFlagsType } from './flags';
import { useHandleInitialFeatureFlags } from './use-handle-initial-feature-flags';

export const FeatureFlagReporter = ({
  featureFlags,
}: {
  featureFlags: ExplorationFlagsType;
}) => {
  useHandleInitialFeatureFlags(featureFlags);
  return null;
};
