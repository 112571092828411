'use client';

import { useEffect } from 'react';
import {
  getMarketingParams,
  setMarketingParams,
} from 'visitor-attributes-common';

/**
 * MarketingParamsInitializer is a React component that runs an effect when the
 * component is mounted. The effect gets the current query parameters from the
 * URL, filters the marketing-related parameters, and sets them in the
 * marketing params cookie. If any of the marketing-related parameters
 * already exist in the cookie, they are not overwritten.
 *
 * The component returns null, so it should be used in a layout or other context
 * where it will not affect the rendering of the page.
 */
export const MarketingParamsInitializer = () => {
  useEffect(() => {
    const persistingMarketingParams = getMarketingParams();

    if (persistingMarketingParams === null) {
      setMarketingParams();
    }
  }, []);

  return null;
};
