import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const BankBuilding = ({
  height = 65,
  width = 65,
  colorPrimary,
  colorSecondary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryHeavy;
  const secondary =
    colorSecondary ?? midwestColors.semanticColorSentimentInfoMedium;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 65"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M58.3316 16.9813L33.1595 2.18718C32.7366 1.93761 32.2132 1.93761 31.7937 2.18718L6.66667 16.9813C6.13286 17.2932 5.88676 17.9033 6.04967 18.496C6.20912 19.0922 6.73253 19.4909 7.34953 19.4909H57.6487C58.2657 19.4909 58.7891 19.0922 58.952 18.496C59.1115 17.9033 58.8654 17.2932 58.3316 16.9813ZM57.9468 18.2222C57.9295 18.2915 57.8636 18.451 57.6487 18.451H7.34953C7.13808 18.451 7.06876 18.2915 7.05143 18.2222C7.03409 18.1563 7.00983 17.983 7.19354 17.8756L32.3206 3.08495C32.3691 3.05375 32.4211 3.03988 32.4766 3.03988C32.5286 3.03988 32.584 3.05375 32.6326 3.08495L57.8047 17.8756C57.9884 17.983 57.9642 18.1563 57.9468 18.2222Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M34.4389 12.936C34.4389 13.7159 33.9051 14.3225 33.1079 14.5166V16.1527H32.068V14.5444C31.1078 14.3953 30.7647 13.7852 30.6433 13.4005L31.6347 13.0851C31.6798 13.2237 31.7803 13.5426 32.5394 13.5426C33.0559 13.5426 33.399 13.3 33.399 12.936C33.399 12.7523 33.399 12.5027 32.4874 12.3051C31.2638 12.0417 30.6433 11.4871 30.6433 10.6587C30.6433 9.83022 31.1876 9.23749 32.0195 9.05724V7.43502H33.0593V9.05724C33.6555 9.18896 34.1096 9.55985 34.3107 10.1041L33.3366 10.4715C33.2049 10.1179 32.834 10.0417 32.5463 10.0417C32.0125 10.0417 31.6832 10.2774 31.6832 10.6587C31.6832 10.7592 31.6832 11.0677 32.7058 11.2895C33.1807 11.39 34.4389 11.6604 34.4389 12.936Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M56.4467 22.5621H8.56003C8.27233 22.5621 8.04009 22.3298 8.04009 22.0421C8.04009 21.7544 8.27233 21.5222 8.56003 21.5222H56.4467C56.7344 21.5222 56.9667 21.7544 56.9667 22.0421C56.9667 22.3298 56.7344 22.5621 56.4467 22.5621Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M58.5816 60.9511L57.001 57.1347C56.8346 56.7361 56.4464 56.4761 56.0131 56.4761H8.85779C8.42451 56.4761 8.03975 56.7361 7.87337 57.1347L6.28928 60.9511C6.15409 61.2804 6.18876 61.6547 6.3898 61.9528C6.58738 62.2509 6.92014 62.4277 7.27717 62.4277H57.5972C57.9542 62.4277 58.287 62.2509 58.4845 61.9528C58.6821 61.6547 58.7202 61.2804 58.5816 60.9511ZM57.5972 61.3878H7.27717L7.24944 61.3497L8.85779 57.516H56.0131L57.6214 61.3497L57.5972 61.3878Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M32.5013 24.3616H35.2431L36.3766 56.4767H32.5013H28.6294L29.7629 24.3616H32.5013Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M14.7054 24.3616H17.4437L18.5772 56.4767H14.7054H10.8301L11.9635 24.3616H14.7054Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M50.3006 24.3616H53.0424L54.1759 56.4767H50.3006H46.4253L47.5622 24.3616H50.3006Z"
        fill={secondary}
        className="fill-secondary"
      />
    </svg>
  );
};

export default BankBuilding;
