import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

export const Play = ({
  height = 36,
  width = 36,
  colorPrimary,
  colorSecondary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryStrong;
  const secondary = colorSecondary ?? midwestColors.white;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        cx="18"
        cy="18"
        r="18"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M14 12.82V23.18C14 23.97 14.87 24.45 15.54 24.02L23.68 18.84C24.3 18.45 24.3 17.55 23.68 17.15L15.54 11.98C14.87 11.55 14 12.03 14 12.82Z"
        fill={primary}
        className="fill-primary"
      />
    </svg>
  );
};

export default Play;
