import { IconProps } from '../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const PieChart = ({
  height = 50,
  width = 50,
  colorPrimary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryMedium;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22.3502 47.4065C10.7127 47.4065 1.17517 37.9565 1.17517 26.2315C1.17517 14.5065 10.7127 5.0565 22.3502 5.0565C22.7002 5.0565 23.0502 5.319 23.0502 5.7565C23.0502 6.194 22.7877 6.4565 22.3502 6.4565C11.4127 6.4565 2.48767 15.3815 2.48767 26.319C2.48767 37.2565 11.4127 46.1815 22.3502 46.1815C33.2877 46.1815 42.2127 37.2565 42.2127 26.319C42.2127 25.969 42.4752 25.619 42.9127 25.619C43.3502 25.619 43.6127 25.8815 43.6127 26.319C43.5252 37.869 33.9877 47.4065 22.3502 47.4065Z"
        fill={primary}
      />
      <path
        d="M29.7 7.6815C29.6125 7.6815 29.525 7.68159 29.4375 7.59409C27.1625 6.71909 24.7125 6.28159 22.2625 6.28159C21.9125 6.28159 21.5625 6.019 21.5625 5.5815C21.5625 5.144 21.825 4.8815 22.2625 4.8815C24.8875 4.8815 27.425 5.31909 29.875 6.28159C30.225 6.36909 30.4 6.80659 30.225 7.15659C30.225 7.59409 29.9625 7.6815 29.7 7.6815Z"
        fill={primary}
      />
      <path
        d="M36.7 41.6315C36.525 41.6315 36.35 41.544 36.2625 41.4565L21.9125 26.8441H1.70002C1.35002 26.8441 1 26.5815 1 26.144C1 25.7065 1.26252 25.444 1.70002 25.444H22.175C22.35 25.444 22.525 25.5315 22.6125 25.619L37.1375 40.4066C37.4 40.6691 37.4 41.1065 37.1375 41.369C37.05 41.6315 36.875 41.6315 36.7 41.6315Z"
        fill={primary}
      />
      <path
        d="M22.3501 26.494C22.0001 26.494 21.6501 26.2315 21.6501 25.794V6.194C21.6501 5.844 21.9126 5.494 22.3501 5.494C22.7876 5.494 23.0501 5.7565 23.0501 6.194V25.794C22.9626 26.2315 22.7001 26.494 22.3501 26.494Z"
        fill={primary}
      />
      <path
        d="M28.2126 23.2565C28.0376 23.2565 27.7751 23.169 27.6876 22.994C27.5126 22.819 27.5127 22.5565 27.6002 22.3815L36.6127 3.13149C36.7877 2.78149 37.1377 2.69406 37.4877 2.78158C45.1002 6.19408 49.4752 12.7565 49.4752 20.894C49.4752 21.244 49.2127 21.5066 48.8627 21.5941L28.2126 23.2565ZM37.5752 4.18149L29.2627 21.8566L48.1627 20.2816C47.9877 13.1066 44.1377 7.33149 37.5752 4.18149Z"
        fill={primary}
      />
    </svg>
  );
};

export default PieChart;
