'use client';

import { useEffect, useState } from 'react';
import { getLicensePlate } from 'visitor-attributes-common';

export const useLicensePlate = (): string | null => {
  const [licensePlate, setLicensePlate] = useState<string | null>(null);
  useEffect(() => {
    setLicensePlate(getLicensePlate());
  }, []);
  return licensePlate;
};
