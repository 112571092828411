import { IconProps } from '../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const HandPlant = ({
  height = 50,
  width = 50,
  colorPrimary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryMedium;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M31.3373 24.5758C30.9984 24.5758 30.6596 24.3216 30.6596 23.898V18.6452C30.6596 13.8161 34.3873 10.0883 39.2165 10.0883H40.2332C40.9957 10.0883 41.504 10.5966 41.504 11.2744V12.2911C41.504 17.1202 37.7762 20.848 32.9471 20.848C32.6082 20.848 32.2693 20.5938 32.2693 20.1702C32.2693 19.8313 32.5234 19.5772 32.9471 19.5772C37.0137 19.5772 40.2332 16.3577 40.2332 12.2911V11.3591H39.3012C35.2346 11.3591 32.0151 14.5786 32.0151 18.6452V23.898C32.0151 24.3216 31.6762 24.5758 31.3373 24.5758Z"
        fill={primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8575 7.80069C19.8575 7.02616 20.4809 6.40277 21.2554 6.40277H22.0179C26.5226 6.40277 30.1018 9.9911 30.1934 14.4808L30.1936 14.4937V15.2562C30.1936 16.0308 29.5702 16.6542 28.7957 16.6542H27.9484C23.5355 16.6542 19.8575 13.0702 19.8575 8.56319V7.80069ZM21.2554 7.6736C21.1827 7.6736 21.1283 7.728 21.1283 7.80069V8.56319C21.1283 12.359 24.228 15.3833 27.9484 15.3833H28.7957C28.8684 15.3833 28.9227 15.3289 28.9227 15.2562V14.5004C28.8417 10.6903 25.8139 7.6736 22.0179 7.6736H21.2554Z"
        fill={primary}
      />
      <path
        d="M30.2361 32.8786C29.4736 32.8786 28.8806 32.7091 28.7958 32.7091L21.0014 31.0994C20.6625 31.0147 20.4083 30.6758 20.4931 30.3369C20.5778 29.998 20.9167 29.7438 21.2556 29.8286L29.05 31.4383C29.4736 31.523 30.9139 31.6924 31.5069 31.2688C31.6764 31.1841 31.7611 31.0147 31.8458 30.7605C32.1 29.4049 30.0667 28.6424 30.0667 28.6424L16.5958 23.898C13.5458 22.7119 11.7667 24.6605 11.6819 24.7452C11.5972 24.8299 9.47917 26.948 7.86944 27.7952C6.25972 28.6424 2.70139 28.7272 2.27778 28.7272C1.93889 28.7272 1.6 28.473 1.6 28.0494C1.6 27.6258 1.93889 27.5411 2.3625 27.5411C3.37917 27.5411 6.175 27.3716 7.36111 26.7786C8.80139 26.0161 10.8347 23.9827 10.8347 23.9827C10.9194 23.898 13.2069 21.2716 17.1042 22.7966L30.4903 27.6258C30.6597 27.7105 33.4556 28.8119 33.1167 31.0994C33.0319 31.6924 32.6931 32.1161 32.2694 32.4549C31.6764 32.7938 30.9139 32.8786 30.2361 32.8786Z"
        fill={primary}
      />
      <path
        d="M27.779 39.2327C27.0165 39.2327 26.0846 39.0633 24.8984 38.4702L10.9193 33.8105L6.25955 36.0133C5.92066 36.1827 5.58177 36.0133 5.41233 35.6744C5.24289 35.3355 5.41233 34.9966 5.75122 34.8272L10.6651 32.5397C10.8346 32.4549 11.004 32.4549 11.1734 32.5397L25.4068 37.3688C27.779 38.4702 28.7957 37.7077 28.7957 37.7077C28.7957 37.7077 28.8805 37.623 28.9652 37.623L44.9776 31.2688C46.4179 30.5911 47.2651 29.8286 47.4346 29.1508C47.5193 28.5577 47.0957 28.1341 47.0957 28.1341L47.0109 28.0494C46.2484 26.948 44.0457 27.5411 44.0457 27.5411L35.3193 30.5911C34.9804 30.6758 34.6415 30.5063 34.4721 30.1674C34.3873 29.8286 34.5568 29.4897 34.8957 29.3202L43.6221 26.2702C43.7915 26.1855 46.7568 25.423 48.0276 27.2022C48.3665 27.5411 48.8748 28.3883 48.7054 29.3202C48.5359 30.5063 47.4346 31.523 45.4859 32.4549L29.5582 38.8091C29.304 38.9786 28.7109 39.2327 27.779 39.2327Z"
        fill={primary}
      />
    </svg>
  );
};

export default HandPlant;
