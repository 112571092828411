import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const PieChart = ({
  height = 65,
  width = 65,
  colorPrimary,
  colorSecondary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryHeavy;
  const secondary =
    colorSecondary ?? midwestColors.semanticColorSentimentInfoMedium;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 65"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M62.5425 30.579C62.5425 15.3463 50.1927 3 34.9636 3V30.579H62.5425Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M31.6415 34.0711V6.49219H31.1102C15.6119 6.49219 3 19.1005 3 34.6024C3 50.1043 15.6119 62.7161 31.1102 62.7161C46.6085 62.7161 59.224 50.1043 59.224 34.6024V34.0711H31.6415ZM30.579 7.55823V33.802L6.39646 23.6091C10.5508 14.3086 19.8017 7.76719 30.579 7.55823ZM4.0625 34.6024C4.0625 31.0678 4.74604 27.6855 5.98563 24.5866L30.3948 34.8751L19.1252 58.8451C10.2073 54.4216 4.0625 45.2168 4.0625 34.6024ZM31.1102 61.6536C27.186 61.6536 23.4531 60.8143 20.085 59.302L31.4502 35.1336H58.1544C57.871 49.8032 45.8506 61.6536 31.1102 61.6536Z"
        fill={primary}
        className="fill-primary"
      />
    </svg>
  );
};

export default PieChart;
