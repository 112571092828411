import { IconProps } from '../../types';

// --------------------------------------------------------------------------

const ThriventLogo = ({
  height = 50,
  width = 50,
  colorPrimary,
  colorSecondary,
  className,
}: IconProps) => {
  return (
    <svg
      width={height}
      height={width}
      className={className}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform={'scale(3) translate(3.5, 3.5)'} fill="none">
        <path
          d="M5.68726 1.03974C5.68726 0.515279 5.21906 0.0898438 4.71576 0.0898438C4.21247 0.0898438 3.74463 0.515279 3.74463 1.03974V1.99192H5.68726V1.03974Z"
          fill="#A60536"
        />
        <path
          d="M6.60028 4.01496C7.10357 4.01496 7.51147 3.52706 7.51147 3.0026C7.51147 2.47813 7.10357 1.99023 6.60028 1.99023H3.74463V4.01496H6.60028Z"
          fill="#E30045"
        />
        <path
          d="M5.68726 4.01562H3.74463V7.53566C3.74463 8.5795 3.94699 9.33871 4.35172 9.81328C4.75645 10.2878 5.49964 10.525 6.58127 10.5247H7.41608V8.54802H6.76476C6.32957 8.54802 6.04119 8.45838 5.89962 8.27912C5.75805 8.09986 5.68726 7.78881 5.68726 7.34598V4.01562Z"
          fill="#11181C"
        />
      </g>
    </svg>
  );
};

export default ThriventLogo;
