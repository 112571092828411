'use client';

import { useEffect } from 'react';
import {
  generateLicensePlate,
  getLicensePlate,
  setLicensePlate,
} from 'visitor-attributes-common';

/**
 * LicensePlateInitializer is a React component that runs an effect when the
 * component is mounted. The effect get the license plate value. If the license plate cookie does not exist,
 * the method generates a value and sets the license_plate cookie to that value.
 * The component returns null, so it should be used in a layout or other context
 * where it will not affect the rendering of the page.
 */
export const LicensePlateInitializer = () => {
  useEffect(() => {
    let licensePlateCookieValue = getLicensePlate();
    if (licensePlateCookieValue === null) {
      licensePlateCookieValue = generateLicensePlate();
      setLicensePlate(licensePlateCookieValue);
    }
  }, []);

  return null;
};
