'use client';

import { desktop } from '@thrivent/midwest-shared';
import styled, { css } from 'styled-components';

// --------------------------------------------------------------------------

export const RootStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  ${desktop} {
    align-items: center;
  }
`;

/**
 * @deprecated - Don't use this, just use Section instead - This adds a bunch of unneeded flex complexity
 */
export const SectionRoot = styled.section`
  ${RootStyle}
`;

export const Section = styled.section`
  width: 100%;
  overflow: hidden;
`;
