'use client';

import { useEffect } from 'react';
import { v4 } from 'uuid';
import { getDeviceId, setDeviceId } from 'visitor-attributes-common';

/**
 * DeviceIdInitializer is a React component that runs an effect when the
 * component is mounted. The effect gets the device id (ANONYMOUS_USER_ID) from the
 * the deviceId cookie, or sets the visitorId cookie to a UUID if it does not exist.
 * The component returns null, so it should be used in a layout or other context
 * where it will not affect the rendering of the page.
 */
export const DeviceIdInitializer = () => {
  useEffect(() => {
    if (getDeviceId() === null) {
      setDeviceId(v4());
    }
  }, []);

  return null;
};
