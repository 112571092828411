import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const CheckList = ({
  height = 65,
  width = 65,
  colorPrimary,
  colorSecondary,
  colorTertiary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryHeavy;
  const secondary =
    colorSecondary ?? midwestColors.semanticColorSentimentInfoMedium;
  const tertiary = colorTertiary ?? midwestColors.white;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 65"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="2"
        y="1.5"
        width="47.2608"
        height="59.5897"
        fill={tertiary}
        className="fill-tertiary"
      />
      <path
        d="M51.2381 49.6085V63.6283H6.52489V5.46912H51.2381V31.5195"
        stroke={primary}
        className="stroke-primary"
        fill="none"
        strokeWidth="0.5166"
        strokeMiterlimit="10"
      />
      <path
        d="M12.5289 19.4622L17.3186 22.9488L23.157 12.8601"
        fill={tertiary}
        className="fill-tertiary"
      />
      <path
        d="M12.5289 19.4622L17.3186 22.9488L23.157 12.8601"
        stroke={secondary}
        className="stroke-secondary"
        fill="none"
        strokeWidth="1.5477"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7272 19.8383H45.2315"
        stroke={primary}
        className="stroke-primary"
        fill="none"
        strokeWidth="0.5166"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.5289 36.1073L17.3186 39.5939L23.157 29.5052"
        fill={tertiary}
        className="fill-tertiary"
      />
      <path
        d="M12.5289 36.1073L17.3186 39.5939L23.157 29.5052"
        stroke={secondary}
        className="stroke-secondary"
        fill="none"
        strokeWidth="1.5477"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7272 36.4821H45.2315"
        stroke={primary}
        className="stroke-primary"
        fill="none"
        strokeWidth="0.5166"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.5289 52.7537L17.3186 56.236L23.157 46.1516"
        fill={tertiary}
        className="fill-tertiary"
      />
      <path
        d="M12.5289 52.7537L17.3186 56.236L23.157 46.1516"
        stroke={secondary}
        className="stroke-secondary"
        fill="none"
        strokeWidth="1.5477"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7272 53.1299H45.2315"
        stroke={primary}
        className="stroke-primary"
        fill="none"
        strokeWidth="0.5166"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M57.015 25.6364L55.5677 27.9834L60.82 31.2223L62.2673 28.8753L57.015 25.6364Z"
        fill="#9BBFC4"
      />
      <path
        d="M57.015 25.6405L55.5677 27.9875L60.82 31.2263L62.2673 28.8793L57.015 25.6405Z"
        stroke={secondary}
        className="stroke-secondary"
        fill="none"
        strokeWidth="0.5166"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M55.5686 27.9833L46.5021 42.686L51.7544 45.9248L60.8209 31.2221L55.5686 27.9833Z"
        fill={tertiary}
        className="fill-tertiary"
      />
      <path
        d="M55.5686 27.9833L46.5021 42.686L51.7544 45.9248L60.8209 31.2221L55.5686 27.9833Z"
        stroke={primary}
        className="stroke-primary"
        fill="none"
        strokeWidth="0.5166"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M51.2126 46.4549L46.2071 49.0655L46.302 43.4213"
        fill={tertiary}
        className="fill-tertiary"
      />
      <path
        d="M51.2126 46.4549L46.2071 49.0655L46.302 43.4213"
        stroke={primary}
        className="stroke-primary"
        fill="none"
        strokeWidth="0.5166"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default CheckList;
