import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const BarGraphUp = ({
  height = 50,
  width = 50,
  colorPrimary,
  colorSecondary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryHeavy;
  const secondary = colorSecondary ?? midwestColors.identityBrandTertiary;
  return (
    <svg
      width={height}
      height={width}
      className={className}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0239 23.5859H9.44043V51.9978H20.0239V23.5859Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M45.81 16.8574H35.2266V51.9968H45.81V16.8574Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M32.8579 29.7764H22.2744V51.9979H32.8579V29.7764Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M40.1512 7.29492V7.31742H40.1484C40.1484 7.31742 40.1512 7.30336 40.1512 7.29492Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M45.8089 51.9961H9.44043V52.8398H45.8089V51.9961Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M40.5204 2.26465L40.1548 7.2709C40.1548 7.2709 40.1548 7.28777 40.1519 7.29621C40.1519 7.30465 40.1519 7.31309 40.1491 7.31871C40.1266 7.52965 39.9466 7.69277 39.7329 7.69277C39.4995 7.69277 39.311 7.50434 39.311 7.2709V7.25684H39.3082L39.5669 3.72434L27.8557 14.6171C27.7066 14.755 27.4816 14.769 27.3185 14.6481L19.6123 8.9809L12.4882 14.6396C12.4123 14.7015 12.3194 14.7296 12.2266 14.7296C12.1029 14.7296 11.9791 14.6762 11.8976 14.5693C11.7513 14.3893 11.7823 14.1221 11.9651 13.9787L19.3423 8.12027C19.4913 8.00215 19.6994 7.99652 19.8541 8.10902L27.5351 13.7621L38.9932 3.1084H35.4494C35.2188 3.1084 35.0276 2.91996 35.0276 2.68652C35.0276 2.45309 35.2188 2.26465 35.4494 2.26465H40.5204Z"
        fill={primary}
        className="fill-primary"
      />
    </svg>
  );
};

export default BarGraphUp;
