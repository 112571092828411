import { IconProps } from '../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const ManOnLaptop = ({
  height = 50,
  width = 50,
  colorPrimary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryMedium;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M50.5529 39.0001H4.95292C3.25292 39.0001 1.95289 38.5001 1.05289 37.5001C-0.347107 35.9001 0.0528926 33.5001 0.0528926 33.4001C0.152893 33.0001 0.452905 32.8 0.752905 32.8H54.6529C55.0529 32.8 55.3529 33.1001 55.3529 33.4001C55.3529 33.5001 55.7529 35.8001 54.3529 37.5001C53.6529 38.5001 52.3529 39.0001 50.5529 39.0001ZM1.55289 34.3C1.55289 34.9 1.6529 35.8001 2.2529 36.5001C2.8529 37.2001 3.75289 37.5001 5.05289 37.5001H50.6529C51.9529 37.5001 52.8529 37.2001 53.4529 36.5001C54.0529 35.8001 54.1529 34.9 54.1529 34.3H1.55289Z"
        fill={primary}
      />
      <path
        d="M52.1531 33.7C51.7531 33.7 51.353 33.4 51.353 32.9V1.59998H4.45306V32.9C4.45306 33.3 4.15308 33.7 3.65308 33.7C3.15308 33.7 2.85303 33.4 2.85303 32.9V0.799988C2.85303 0.399988 3.15308 0 3.65308 0H52.053C52.453 0 52.853 0.299988 52.853 0.799988V32.9C52.953 33.4 52.5531 33.7 52.1531 33.7Z"
        fill={primary}
      />
      <path
        d="M28.1533 15C25.3533 15 23.1533 12.7 23.1533 10C23.1533 7.3 25.4533 5 28.1533 5C30.8533 5 33.1533 7.3 33.1533 10C33.1533 12.7 30.9533 15 28.1533 15ZM28.1533 6.39999C26.1533 6.39999 24.6533 7.99999 24.6533 9.89999C24.6533 11.8 26.2533 13.4 28.1533 13.4C30.0533 13.4 31.6533 11.8 31.6533 9.89999C31.6533 7.99999 30.0533 6.39999 28.1533 6.39999Z"
        fill={primary}
      />
      <path
        d="M36.1531 31.3C35.7531 31.3 35.4531 31 35.4531 30.6L34.7531 21.3C34.1531 18.3 27.9531 18.7 27.9531 18.7C22.1531 18.7 21.553 21.3 21.553 21.4C21.553 21.4 21.053 26.6 20.853 30.6C20.853 31 20.453 31.3 20.053 31.3C19.653 31.3 19.353 30.9 19.353 30.5C19.553 26.5 20.053 21.2 20.053 21.2C20.053 21 20.8531 17.2 27.9531 17.2C28.2531 17.2 35.3531 16.7 36.2531 21.1L36.9531 30.5C36.8531 30.9 36.5531 31.3 36.1531 31.3Z"
        fill={primary}
      />
    </svg>
  );
};

export default ManOnLaptop;
