'use client';

import { useEffect, useState } from 'react';
import { getDeviceId } from 'visitor-attributes-common';

export const useDeviceId = (): string | null => {
  const [visitorId, setVisitorId] = useState<string | null>(null);
  useEffect(() => {
    setVisitorId(getDeviceId());
  }, []);
  return visitorId;
};
