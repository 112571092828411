import { IconProps } from '../../types';

// --------------------------------------------------------------------------

const PersonIcon = ({ height = 50, width = 50, className }: IconProps) => {
  return (
    <svg
      width={height}
      height={width}
      className={className}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        transform={'scale(3) translate(1, 0)'}
        d="M8.11534 8.21739C9.57364 8.21739 10.7548 7.05 10.7548 5.6087C10.7548 4.16739 9.57364 3 8.11534 3C6.65704 3 5.47588 4.16739 5.47588 5.6087C5.47588 7.05 6.65704 8.21739 8.11534 8.21739ZM8.11534 9.52174C6.3535 9.52174 2.83643 10.3957 2.83643 12.1304V12.7826C2.83643 13.1413 3.13336 13.4348 3.49629 13.4348H12.7344C13.0973 13.4348 13.3942 13.1413 13.3942 12.7826V12.1304C13.3942 10.3957 9.87717 9.52174 8.11534 9.52174Z"
        fill="#BE1D3D"
      />
    </svg>
  );
};

export default PersonIcon;
