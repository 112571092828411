import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const PersonOnLaptop = ({
  height = 65,
  width = 65,
  colorPrimary,
  colorSecondary,
  colorTertiary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryHeavy;
  const secondary =
    colorSecondary ?? midwestColors.semanticColorSentimentInfoMedium;
  const tertiary = colorTertiary ?? midwestColors.white;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 65"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M40.1971 59.3433V47.195C40.1971 37.9498 31.2296 30.4578 21.5918 30.4578C11.954 30.4578 2.9826 37.9538 2.9826 47.195V59.3433H40.1971Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M40.1971 59.3433V47.195C40.1971 37.9498 31.2296 30.4578 21.5918 30.4578C11.954 30.4578 2.9826 37.9538 2.9826 47.195V59.3433H40.1971Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M32.4555 15.8317C32.4555 21.7571 27.6525 26.5602 21.727 26.5602C15.8016 26.5602 10.9986 21.7571 10.9986 15.8317C10.9986 9.90624 15.8016 5.10321 21.727 5.10321C27.6525 5.10321 32.4555 9.90624 32.4555 15.8317Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M32.4555 15.8317C32.4555 21.7571 27.6525 26.5602 21.727 26.5602C15.8016 26.5602 10.9986 21.7571 10.9986 15.8317C10.9986 9.90624 15.8016 5.10321 21.727 5.10321C27.6525 5.10321 32.4555 9.90624 32.4555 15.8317Z"
        stroke={secondary}
        className="stroke-secondary"
        strokeWidth="0.976"
        strokeMiterlimit="10"
        fill="none"
      />
      <path
        d="M7.87216 19.4514C6.11911 19.4514 4.69922 18.0315 4.69922 16.2785C4.69922 14.5254 6.11911 13.1055 7.87216 13.1055H8.56226V18.7573C8.56226 19.1381 8.25291 19.4474 7.87216 19.4474V19.4514Z"
        stroke={primary}
        className="stroke-primary fill-tertiary"
        strokeWidth="0.976"
        strokeMiterlimit="10"
        fill={tertiary}
      />
      <path
        d="M8.56312 13.5998C8.60675 13.334 8.65832 13.0723 8.72178 12.8145C10.0901 6.89695 15.3968 2.4826 21.7308 2.4826C29.1039 2.4826 35.0849 8.45961 35.0849 15.8327"
        stroke={primary}
        className="stroke-primary"
        strokeWidth="0.976"
        strokeMiterlimit="10"
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M34.8452 18.338C34.8015 18.564 34.7539 18.7901 34.6984 19.0162C34.2146 20.9913 33.2944 22.792 32.0411 24.315C31.5096 24.9575 30.9226 25.5524 30.2841 26.0839"
        stroke={primary}
        className="stroke-primary"
        strokeWidth="0.976"
        strokeMiterlimit="10"
        fill="none"
      />
      <path
        d="M35.4857 19.4514C37.2387 19.4514 38.6586 18.0315 38.6586 16.2785C38.6586 14.5254 37.2387 13.1055 35.4857 13.1055H34.7956V18.7573C34.7956 19.1381 35.105 19.4474 35.4857 19.4474V19.4514Z"
        stroke={primary}
        className="stroke-primary fill-tertiary"
        strokeWidth="0.976"
        strokeMiterlimit="10"
        fill={tertiary}
      />
      <path
        d="M27.2709 62.6427L18.4382 59.6839H27.8618"
        stroke={primary}
        className="stroke-primary fill-tertiary no-flip"
        strokeWidth="0.976"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={tertiary}
      />
      <path
        d="M60.3778 62.639H27.8672L29.3902 39.0483H61.9048L60.3778 62.639Z"
        stroke={primary}
        className="stroke-primary fill-tertiary no-flip"
        strokeWidth="0.976"
        strokeMiterlimit="10"
        fill={tertiary}
      />
      <path
        d="M44.9023 47.6855C47.2146 44.4887 50.9824 46.682 50.7405 50.0612C50.5184 53.131 44.9023 56.3277 44.9023 56.3277C44.9023 56.3277 39.2862 53.131 39.0641 50.0612C38.8222 46.682 42.59 44.4887 44.9023 47.6855Z"
        stroke={primary}
        className="stroke-primary fill-tertiary no-flip"
        strokeWidth="0.976"
        strokeMiterlimit="10"
        fill={tertiary}
      />
    </svg>
  );
};

export default PersonOnLaptop;
