import { IconProps } from '../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const PuzzlePieces = ({
  height = 50,
  width = 50,
  colorPrimary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryMedium;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 52 54"
      fill="none"
      className={className}
    >
      <path
        d="M27.6262 32.0065H23.7389C23.2101 32.0065 22.9242 31.4181 23.2244 30.9804C23.746 30.2198 23.9747 29.2367 23.7174 28.189C23.4244 26.9763 22.4383 26.0005 21.2236 25.7135C18.9798 25.1896 16.9861 26.8831 16.9861 29.0501C16.9861 29.7605 17.2076 30.4207 17.5792 30.966C17.8793 31.4109 17.6006 32.0065 17.0647 32.0065H10.0261V52.4284H51V32.0065H48.3132"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.4264 32.0061V23.1585C30.4264 22.7136 30.9194 22.4696 31.2839 22.7208C31.9198 23.1657 32.7416 23.3522 33.6206 23.137C34.6353 22.8858 35.457 22.0535 35.6928 21.0345C36.1287 19.1401 34.7138 17.4539 32.8988 17.4539C32.3057 17.4539 31.7484 17.6404 31.2982 17.949C30.9266 18.2001 30.4264 17.9633 30.4264 17.5184V11.5698H50.9919V31.9989"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.1269 32.0065H43.2673C42.7386 32.0065 42.4527 31.4181 42.7528 30.9804C43.2745 30.2198 43.5032 29.2367 43.2459 28.189C42.9529 26.9763 41.9668 26.0005 40.752 25.7135C38.5082 25.1896 36.5146 26.8831 36.5146 29.0501C36.5146 29.7605 36.7361 30.4207 37.1077 30.966C37.4078 31.4109 37.1291 32.0065 36.5932 32.0065H30.4264"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2003 1.00696L1 10.2707L10.2038 28.4969L11.8544 27.6573L16.5135 25.2894C16.978 25.0526 16.9708 24.3924 16.5135 24.1413C15.706 23.6964 15.07 22.9214 14.8199 21.8738C14.5341 20.6611 14.97 19.3408 15.9132 18.5443C17.664 17.0661 20.1935 17.6832 21.1654 19.6135C21.4869 20.2521 21.587 20.9338 21.5084 21.5868C21.4369 22.1178 21.9586 22.5267 22.4302 22.2828L26.4961 20.209L28.4112 19.2331L25.6315 13.7294"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0502 2.69347L19.2002 1"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5177 13.5142L24.7888 12.0719C24.5887 11.6772 24.9174 11.2323 25.3605 11.2897C26.125 11.3974 26.9468 11.1893 27.6328 10.6009C28.426 9.91918 28.7833 8.79978 28.5332 7.78084C28.0687 5.89364 26.0465 5.03257 24.4315 5.85777C23.9027 6.13044 23.4883 6.53946 23.2239 7.0274C23.0095 7.42206 22.4593 7.4364 22.2521 7.03456L20.0511 2.67896"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.4258 32.007V38.6732C30.4258 39.2042 29.8398 39.4912 29.4039 39.1898C28.6465 38.666 27.6675 38.4364 26.6242 38.6947C25.4166 38.9889 24.4448 39.9791 24.1589 41.199C23.6373 43.4521 25.3237 45.4542 27.4817 45.4542C28.1891 45.4542 28.8466 45.2317 29.3896 44.8586C29.8327 44.5572 30.4258 44.837 30.4258 45.3752V52.4217"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.2014 34.6318L48.0979 43.5655"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.0468 34.6318L48.1194 37.7174"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.3351 40.48L42.4745 49.6577"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.356 46.379L36.6216 49.6583"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.3491 34.6249L48.0908 49.4283"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4336 21.7881L10.0541 23.008"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9097 15.8827L21.816 16.959"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4938 10.9957L7.56665 18.1642"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2281 6.56152L5.30103 13.73"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PuzzlePieces;
