import { IconProps } from '../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const DottedPath = ({
  height = 50,
  width = 50,
  colorPrimary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryMedium;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M37.9369 23.2003C36.9011 21.8774 36.3641 20.9532 34.2542 20.5101C32.1315 20.0227 29.7786 20.2063 27.707 20.1746"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="4 6"
      />
      <path
        d="M11.2109 36.1211H27.5276"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.37722 9.64844C1.15984 10.3067 1.03197 11.003 1 11.7183"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.42969 14.9648C2.59973 18.8261 6.84513 20.5668 10.8412 20.1996"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="5 2.8"
      />
      <path
        d="M14.1094 20.2012H16.2385"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.5353 4.25586H27.4062"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.18 4.25586H17.707"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6799 4.25586H11.5508"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.76063 35.8678C7.72866 32.5003 2.96537 31.4242 1.48203 34.4689C-0.570337 39.4315 7.28111 41.2925 7.76063 35.9944V35.8741V35.8678Z"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.68641 5.38927C8.65445 2.02177 3.89115 0.945679 2.40782 3.99035C0.355444 8.95298 8.20689 10.814 8.68641 5.51588V5.39561V5.38927Z"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.0223 4.192C38.9904 0.824507 34.2271 -0.251586 32.7438 2.79309C30.6914 7.75571 38.5428 9.61672 39.0223 4.31861V4.19834V4.192Z"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.4481 20.0494C25.4162 16.6819 20.6529 15.6058 19.1695 18.6505C17.1172 23.6131 24.9686 25.4741 25.4481 20.176V20.0557V20.0494Z"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.8052 33.5C29.5904 34.9875 29.5392 37.3676 31.2527 38.507C34.5519 40.5895 37.8638 36.7789 35.6516 33.6836"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.1485 35.816C33.174 32.7461 33.0976 28.2771 33.1803 25.0742C33.2631 26.5871 33.1931 33.3221 33.2185 35.816"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.9986 30.6331C42.3081 29.9495 40.9974 28.6518 40.2685 27.9302C40.1918 27.8479 40.179 27.8733 40.2301 27.8226C40.7416 27.3162 42.7045 25.3729 42.9986 25.0818C42.8771 25.0754 42.8068 25.0818 42.6278 25.0818C40.8056 25.0881 32.9669 25.0628 33.1843 25.0944V25.2843C33.2099 26.4617 33.1396 30.6584 33.2035 30.6331C35.3198 30.6331 40.4795 30.6331 42.9986 30.6331Z"
        stroke={primary}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DottedPath;
