import { IconProps } from '../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const BarChart = ({
  height = 50,
  width = 50,
  colorPrimary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryMedium;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M24.3102 16.284C24.2257 16.284 24.0567 16.284 23.9721 16.1994L15.5201 10.452L7.91325 14.1709C7.57517 14.34 7.23709 14.1709 7.06804 13.9174C6.899 13.5793 7.06804 13.2412 7.32161 13.0722L15.2665 9.18424C15.4356 9.09972 15.6891 9.09972 15.9427 9.26876L24.3102 15.0162L40.7917 2.84522C41.0453 2.67618 41.4679 2.67619 41.6369 3.01427C41.8059 3.26783 41.8059 3.69041 41.4679 3.85945L24.6483 16.284C24.5638 16.1994 24.4793 16.284 24.3102 16.284Z"
        fill={primary}
      />
      <path
        d="M40.2001 9.09972H40.1155C39.7775 9.0152 39.5239 8.67712 39.6084 8.33903L40.4536 3.69041L35.8895 3.26781C35.5514 3.26781 35.2979 2.92973 35.2979 2.59165C35.2979 2.25357 35.636 2 35.974 2L41.2143 2.50711C41.3834 2.50711 41.5524 2.59166 41.6369 2.7607C41.7214 2.92974 41.806 3.09877 41.7214 3.26781L40.7072 8.5926C40.7072 8.93068 40.4536 9.09972 40.2001 9.09972Z"
        fill={primary}
      />
      <path
        d="M43.0738 48.9088H7.74429C7.40621 48.9088 7.06813 48.6552 7.06813 48.2326C7.06813 47.81 7.32169 47.5565 7.74429 47.5565H43.0738C43.4119 47.5565 43.75 47.81 43.75 48.2326C43.75 48.6552 43.4119 48.9088 43.0738 48.9088Z"
        fill={primary}
      />
      <path
        d="M15.8583 45.0209C15.5202 45.0209 15.1821 44.7673 15.1821 44.3447V21.8622C15.1821 21.017 14.5059 20.3409 13.6607 20.3409H10.787C9.94182 20.3409 9.26566 21.017 9.26566 21.8622V44.3447C9.26566 44.6827 9.0121 45.0209 8.58949 45.0209C8.16689 45.0209 7.91333 44.7673 7.91333 44.3447V21.8622C7.91333 20.3409 9.18114 19.0731 10.7025 19.0731H13.5762C15.0976 19.0731 16.3654 20.3409 16.3654 21.8622V44.3447C16.5344 44.7673 16.1963 45.0209 15.8583 45.0209Z"
        fill={primary}
      />
      <path
        d="M28.9589 45.021C28.6208 45.021 28.2828 44.7674 28.2828 44.3448V27.6943C28.2828 26.8491 27.6066 26.1729 26.7614 26.1729H23.8877C23.0425 26.1729 22.3663 26.8491 22.3663 27.6943V44.3448C22.3663 44.6829 22.1128 45.021 21.6902 45.021C21.2676 45.021 21.014 44.7674 21.014 44.3448V27.6943C21.014 26.1729 22.2818 24.9051 23.8032 24.9051H26.6769C28.1982 24.9051 29.4661 26.1729 29.4661 27.6943V44.3448C29.5506 44.7674 29.297 45.021 28.9589 45.021Z"
        fill={primary}
      />
      <path
        d="M42.0596 45.0209C41.7215 45.0209 41.3834 44.7674 41.3834 44.3448V16.622C41.3834 15.7768 40.7073 15.1007 39.8621 15.1007H36.9884C36.1432 15.1007 35.467 15.7768 35.467 16.622V44.3448C35.467 44.6828 35.2135 45.0209 34.7908 45.0209C34.3682 45.0209 34.1147 44.7674 34.1147 44.3448V16.622C34.1147 15.1007 35.3825 13.8329 36.9039 13.8329H39.7776C41.2989 13.8329 42.5667 15.1007 42.5667 16.622V44.3448C42.6513 44.7674 42.3977 45.0209 42.0596 45.0209Z"
        fill={primary}
      />
    </svg>
  );
};

export default BarChart;
