import { IconProps } from '../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const PinOnMap = ({
  height = 50,
  width = 50,
  colorPrimary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryMedium;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 56 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M54.7414 44.4006H0.741432C0.541432 44.4006 0.241426 44.3006 0.141426 44.1006C0.0414262 43.9006 -0.0585799 43.6006 0.0414201 43.4006L11.0414 12.2006C11.1414 11.9006 11.4414 11.7006 11.7414 11.7006H15.3414C15.7414 11.7006 16.1414 12.0005 16.1414 12.5005C16.1414 13.0005 15.8414 13.3006 15.3414 13.3006H12.2414L1.84144 43.0005H53.7414L43.2414 13.2006H40.2414C39.8414 13.2006 39.4414 12.9006 39.4414 12.4006C39.4414 11.9006 39.7414 11.6006 40.2414 11.6006H43.8414C44.1414 11.6006 44.4414 11.8006 44.5414 12.1006L55.5414 43.3006C55.6414 43.5006 55.5414 43.8005 55.4414 44.0005C55.3414 44.2006 55.0414 44.4006 54.7414 44.4006Z"
        fill={primary}
      />
      <path
        d="M27.7417 14.5005C25.2417 14.5005 23.2417 12.5005 23.2417 10.0005C23.2417 7.50049 25.2417 5.50049 27.7417 5.50049C30.2417 5.50049 32.2417 7.50049 32.2417 10.0005C32.2417 12.5005 30.2417 14.5005 27.7417 14.5005ZM27.7417 7.00049C26.0417 7.00049 24.7417 8.30049 24.7417 10.0005C24.7417 11.7005 26.0417 13.0005 27.7417 13.0005C29.4417 13.0005 30.7417 11.7005 30.7417 10.0005C30.7417 8.40049 29.4417 7.00049 27.7417 7.00049Z"
        fill={primary}
      />
      <path
        d="M27.7415 29.0005C27.5415 29.0005 27.3415 28.9005 27.2415 28.8005C26.8415 28.4005 17.7415 19.0005 17.5415 10.8005C17.6415 7.20052 18.6415 4.50051 20.7415 2.60051C23.6415 -0.0994883 27.3415 0.000475126 27.8415 0.000475126C28.3415 0.000475126 32.0415 -0.0994883 34.9415 2.60051C36.9415 4.50051 38.0415 7.20052 38.1415 10.8005C37.9415 19.0005 28.8415 28.4005 28.4415 28.8005C28.1415 28.9005 27.9415 29.0005 27.7415 29.0005ZM27.6415 1.50048C26.9415 1.50048 23.9415 1.60049 21.7415 3.70049C20.0415 5.30049 19.1415 7.70052 19.0415 10.8005C19.2415 17.3005 25.8415 25.0005 27.8415 27.2005C29.8415 25.0005 36.4415 17.3005 36.6415 10.8005C36.5415 7.70052 35.6415 5.40052 33.9415 3.80052C31.4415 1.50052 27.9415 1.60051 27.9415 1.60051H27.8415C27.7415 1.50051 27.7415 1.50048 27.6415 1.50048Z"
        fill={primary}
      />
    </svg>
  );
};

export default PinOnMap;
