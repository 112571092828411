import { IconProps } from '../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const PinOnMap = ({
  height = 50,
  width = 50,
  colorPrimary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryMedium;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M41.53 20.4799L38.17 21.4299C38.17 21.4299 33.89 20.4299 32.04 19.6999C30.19 18.9699 25.77 19.7699 24.86 22.1199L23.53 25.1299C23.53 25.1299 22.48 27.1599 24.37 27.8299C26.26 28.4999 26.75 27.2999 27.35 25.8999C27.35 25.8999 28.02 23.6899 29.56 23.8999C29.56 23.8999 32.85 24.2199 38.01 28.2499L43.69 33.0199L46.42 32.2799"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.17 20.0501C27.17 20.0501 25.65 18.6701 23.44 20.0501L20.01 22.3501L17.45 20.8901"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6 31.8501L16.1 34.9301"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4846 36.3139L17.97 35.6039C18.4441 34.9105 19.3949 34.7247 20.0965 35.2044C20.79 35.6785 20.9758 36.6293 20.496 37.331L20.0107 38.0409C19.5366 38.7343 18.5858 38.9202 17.8841 38.4404C17.1907 37.9663 17.0049 37.0156 17.4846 36.3139Z"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.48 42.3601C22.78 41.8801 22.6 40.9401 23.08 40.2401L23.58 39.5101C24.05 38.8201 25 38.6401 25.7 39.1201C26.4 39.6001 26.58 40.5401 26.1 41.2401L25.6 41.9701C25.13 42.6601 24.18 42.8401 23.48 42.3601Z"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.48 41.12C19.73 40.61 19.67 39.39 20.35 38.41L21.06 37.37C21.73 36.38 22.88 36 23.63 36.51C24.38 37.02 24.44 38.24 23.76 39.22L23.05 40.26C22.38 41.25 21.23 41.63 20.48 41.12Z"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.71 37.51C29.71 37.51 31.48 41.82 34.81 41.16C34.81 41.16 36.35 40.82 36.82 39.05"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.74 36.8199C40.74 36.8199 41.4 39.5499 38.4 39.2399C34.93 38.6399 32.75 34.3999 32.75 34.3999"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.3199 31.3601C35.3199 31.3601 39.5999 37.2701 41.8099 37.0101C44.0199 36.7501 43.8899 33.2301 43.8899 33.2301"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.23 16.1001L13.97 18.4401L5.4 33.0001L1.75 30.9401"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.75 31.36L51.66 33.04L44.99 17.53L48.81 15.75"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PinOnMap;
