import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const PeopleInHouse = ({
  height = 65,
  width = 65,
  colorPrimary,
  colorSecondary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryHeavy;
  const secondary =
    colorSecondary ?? midwestColors.semanticColorSentimentInfoMedium;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 65"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M27.8091 14.9161L24.1867 14.9263V14.9161H27.8091Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M64.2497 30.4112L63.3357 26.5552H63.3391L64.2497 30.4112Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M15.1036 26.5552L14.3521 29.7172L15.1003 26.5552H15.1036Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M62.8245 54.2107H60.3396V30.4112H64.2497L63.3357 26.5552L60.58 14.9161H43.0401L39.218 10.6471L35.3992 14.9161H28.8247V11.6052C28.8247 11.0534 28.3711 10.5997 27.8193 10.5997H24.1732C23.6214 10.5997 23.1711 11.0534 23.1711 11.6052V14.9161H17.8594L15.1036 26.5552L14.3521 29.7172L14.1896 30.4112H18.0997V54.2107H9.45339V49.0445C11.1393 47.3721 12.3039 45.1885 12.7339 42.856C13.1773 40.4456 12.8422 37.9031 11.7859 35.6958C11.4135 34.9138 10.7669 33.976 10.0526 33.1872C9.76823 32.8758 9.36536 32.6964 8.94557 32.6964C8.52578 32.6964 8.12292 32.8758 7.83854 33.1872C7.12422 33.976 6.4776 34.9138 6.10521 35.6958C5.04896 37.9031 4.7138 40.4456 5.15729 42.856C5.58724 45.1885 6.75182 47.3721 8.43776 49.0445V54.2107H1.50781C1.22682 54.2107 1 54.4375 1 54.7185C1 54.9995 1.22682 55.2263 1.50781 55.2263H62.8245C63.1021 55.2263 63.3323 54.9995 63.3323 54.7185C63.3323 54.4375 63.1021 54.2107 62.8245 54.2107ZM8.94557 41.468C8.66458 41.468 8.43776 41.6948 8.43776 41.9758V47.5482C7.28333 46.15 6.48438 44.4573 6.15599 42.6698C5.74974 40.4727 6.05781 38.1503 7.01927 36.1326C7.34766 35.4453 7.95026 34.5786 8.5901 33.8677C8.6849 33.7661 8.81016 33.712 8.94557 33.712C9.08099 33.712 9.20625 33.7661 9.29766 33.8677C9.94089 34.5786 10.5435 35.4453 10.8719 36.1326C11.8333 38.1503 12.1414 40.4727 11.7352 42.6698C11.4068 44.4573 10.6078 46.15 9.45339 47.5482V41.9758C9.45339 41.6948 9.22656 41.468 8.94557 41.468ZM59.7776 15.9318L62.293 26.5552H53.457L43.9474 15.9318H59.7776ZM27.8091 11.6052V14.9161L24.1867 14.9263V14.9161L24.1732 11.6154L27.8091 11.6052ZM18.6617 15.9318H34.4919L24.9823 26.5552H16.1464L18.6617 15.9318ZM15.4727 29.3956L15.6385 28.6914L15.906 27.5708H25.4359L39.218 12.1706L53.0034 27.5708H62.5333L62.9667 29.3956H52.1773L39.218 14.6555L26.262 29.3956H15.4727ZM23.8617 54.2107H19.1154V30.4112H23.8617V54.2107ZM53.562 54.2107H24.8773V30.4112H26.7224L39.218 16.1924L51.7169 30.4112H53.562V54.2107ZM59.324 54.2107H54.5776V30.4112H59.324V54.2107Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M38.6661 33.8609C38.6661 35.3505 37.4542 36.5659 35.9612 36.5659C34.4682 36.5659 33.2563 35.3505 33.2563 33.8609C33.2563 32.3714 34.4716 31.156 35.9612 31.156C37.4508 31.156 38.6661 32.368 38.6661 33.8609Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M46.1208 35.8888C46.1208 37.0466 45.3693 38.025 44.33 38.3703C44.0693 38.4583 43.7917 38.5057 43.5039 38.5057C43.2161 38.5057 42.9284 38.4549 42.6609 38.3635C41.6318 38.0115 40.8904 37.0398 40.8904 35.8888C40.8904 34.4432 42.0617 33.2719 43.5039 33.2719C44.9461 33.2719 46.1208 34.4432 46.1208 35.8888Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M47.3667 42.768V54.2107H39.5701V42.768C39.5701 42.0536 39.8443 41.3766 40.3013 40.8112C41.0224 39.9174 42.1971 39.3115 43.4701 39.3115C45.5453 39.3115 47.3667 40.9263 47.3667 42.768Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M40.2133 39.8904L40.0169 40.0969C39.2924 40.8552 38.893 41.8031 38.893 42.768V54.2107H30.5818V42.2026C30.5818 39.7076 32.9956 37.5206 35.7479 37.5206C37.4 37.5206 39.0318 38.3229 40.0135 39.6263C40.0237 39.6365 40.0339 39.65 40.044 39.6635L40.2133 39.8904Z"
        fill={secondary}
        className="fill-secondary"
      />
    </svg>
  );
};

export default PeopleInHouse;
