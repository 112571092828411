import { IconProps } from '../../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const PersonInCircle = ({
  height = 65,
  width = 65,
  colorPrimary,
  colorSecondary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryHeavy;
  const secondary =
    colorSecondary ?? midwestColors.semanticColorSentimentInfoMedium;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 65 65"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M43.6956 41.6542V48.662H22.7703V41.6542C22.7703 36.6133 27.6589 32.1919 33.2346 32.1919C38.8104 32.1919 43.6956 36.6133 43.6956 41.6542Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M39.7279 24.3716C39.7279 27.9128 36.8469 30.7904 33.3091 30.7904C29.7714 30.7904 26.8904 27.9128 26.8904 24.3716C26.8904 20.8305 29.768 17.9529 33.3091 17.9529C36.8503 17.9529 39.7279 20.8339 39.7279 24.3716Z"
        fill={secondary}
        className="fill-secondary"
      />
      <path
        d="M10.9484 19.1276C11.0398 19.1919 11.1414 19.2258 11.2464 19.2258C11.4021 19.2258 11.5578 19.1547 11.6594 19.0159C12.7495 17.5128 14.0021 16.118 15.3833 14.8721C15.5898 14.6826 15.6068 14.3643 15.4206 14.1544C15.231 13.9445 14.9128 13.931 14.7029 14.1172C13.2708 15.4104 11.9708 16.856 10.8401 18.4167C10.6742 18.6435 10.725 18.9617 10.9518 19.1276H10.9484Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M19.6219 11.6594C19.7099 11.6594 19.7945 11.6357 19.8758 11.5917C21.4839 10.6573 23.1935 9.89219 24.9573 9.31667C25.2247 9.22865 25.3703 8.94427 25.2823 8.67682C25.1943 8.40937 24.9099 8.2638 24.6425 8.35182C22.8109 8.95104 21.037 9.74323 19.3646 10.7148C19.1208 10.857 19.0396 11.1651 19.1818 11.4089C19.2766 11.5714 19.4458 11.6628 19.6219 11.6628V11.6594Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M30.462 61.144C28.6102 60.9544 26.7786 60.5651 25.0148 59.993C24.7474 59.9083 24.463 60.0539 24.375 60.318C24.287 60.5854 24.4359 60.8698 24.7 60.9578C26.5315 61.5503 28.4341 61.9531 30.357 62.1529C30.374 62.1529 30.3909 62.1529 30.4112 62.1529C30.6685 62.1529 30.8885 61.9599 30.9156 61.6958C30.9427 61.4182 30.743 61.1677 30.462 61.1372V61.144Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M6.59141 34.6768C6.59141 34.3958 6.36458 34.169 6.08359 34.169C5.8026 34.169 5.57578 34.3958 5.57578 34.6768C5.57578 36.6133 5.78229 38.5464 6.18177 40.4253C6.23255 40.6656 6.44245 40.8281 6.67943 40.8281C6.71328 40.8281 6.75052 40.8247 6.78438 40.818C7.05859 40.7604 7.23464 40.4896 7.17708 40.2154C6.79115 38.4076 6.59818 36.5456 6.59479 34.6768H6.59141Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M41.4781 59.9862C39.7143 60.5583 37.8828 60.9477 36.031 61.1406C35.7534 61.1711 35.5503 61.4182 35.5773 61.6992C35.6044 61.9599 35.8245 62.1529 36.0818 62.1529C36.0987 62.1529 36.1156 62.1529 36.1359 62.1529C38.0589 61.9531 39.9615 61.5503 41.7896 60.9544C42.057 60.8664 42.2026 60.582 42.1146 60.3146C42.0266 60.0471 41.7422 59.9016 41.4747 59.9896L41.4781 59.9862Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M8.89688 45.5068C8.78177 45.2495 8.48047 45.1344 8.22656 45.2495C7.96927 45.3646 7.85417 45.6659 7.96927 45.9198C8.75469 47.6768 9.72969 49.3594 10.8672 50.9201C10.9654 51.0555 11.1211 51.1299 11.2768 51.1299C11.3818 51.1299 11.4867 51.0995 11.5747 51.0318C11.8016 50.8659 11.8523 50.5477 11.6865 50.3208C10.5896 48.8177 9.65182 47.1961 8.89349 45.5034L8.89688 45.5068Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M54.7557 18.9448C54.8539 19.0802 55.0096 19.1547 55.1654 19.1547C55.2703 19.1547 55.3753 19.1242 55.4633 19.0565C55.6901 18.8906 55.7375 18.5724 55.575 18.3456C54.4375 16.7883 53.1341 15.3461 51.6987 14.0596C51.4888 13.8734 51.1706 13.8904 50.981 14.0969C50.7948 14.3068 50.8117 14.625 51.0182 14.8146C52.3995 16.057 53.6589 17.4451 54.7523 18.9448H54.7557Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M41.424 9.28958C43.1911 9.85833 44.9008 10.6201 46.5122 11.5477C46.5935 11.5951 46.6781 11.6154 46.7661 11.6154C46.9422 11.6154 47.1115 11.524 47.2062 11.3615C47.3451 11.1177 47.2638 10.8063 47.0201 10.6674C45.3477 9.7026 43.5669 8.9138 41.7354 8.32135C41.468 8.23672 41.1836 8.38229 41.0956 8.64974C41.0109 8.91719 41.1565 9.20156 41.424 9.28958Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M58.2495 45.2258C57.9922 45.1107 57.6943 45.2258 57.5792 45.4831C56.8242 47.1758 55.8865 48.8008 54.793 50.3039C54.6271 50.5307 54.6779 50.849 54.9047 51.0148C54.9961 51.0792 55.101 51.113 55.2026 51.113C55.3583 51.113 55.5141 51.0419 55.6122 50.9031C56.7497 49.3424 57.7214 47.6565 58.5068 45.8995C58.6219 45.6422 58.5068 45.3443 58.2495 45.2292V45.2258Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M60.3789 34.0776C60.0979 34.0776 59.8711 34.3044 59.8711 34.5854V34.6464C59.8711 36.5117 59.6781 38.3771 59.2956 40.1849C59.238 40.4591 59.4141 40.7299 59.6883 40.7875C59.7221 40.7943 59.7594 40.7977 59.7932 40.7977C60.0268 40.7977 60.2401 40.6352 60.2909 40.3948C60.687 38.5159 60.8901 36.5828 60.8901 34.643V34.5854C60.8901 34.3044 60.6633 34.0776 60.3823 34.0776H60.3789Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M33.3159 3.52422C31.1221 3.52422 29.3414 5.30833 29.3414 7.4987C29.3414 9.68906 31.1221 11.4732 33.3159 11.4732C35.5096 11.4732 37.2904 9.69245 37.2904 7.4987C37.2904 5.30495 35.5063 3.52422 33.3159 3.52422ZM33.3159 10.4576C31.9076 10.4576 30.7294 9.4724 30.4315 8.15208C30.3841 7.94219 30.357 7.72552 30.357 7.4987C30.357 7.37682 30.3672 7.25833 30.3807 7.13984C30.5602 5.67734 31.806 4.53984 33.3159 4.53984C34.8258 4.53984 36.1021 5.70443 36.2578 7.19062C36.268 7.29219 36.2747 7.39375 36.2747 7.4987C36.2747 7.70859 36.251 7.9151 36.2104 8.11146C35.926 9.4487 34.7378 10.4576 33.3159 10.4576Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M7.57656 21.9443C5.3862 21.9443 3.60208 23.7284 3.60208 25.9187C3.60208 28.1091 5.3862 29.8932 7.57656 29.8932C9.76693 29.8932 11.551 28.1125 11.551 25.9187C11.551 23.725 9.76693 21.9443 7.57656 21.9443ZM7.57656 28.8776C7.45807 28.8776 7.34297 28.8708 7.23125 28.8573C6.88255 28.8167 6.55417 28.7151 6.25625 28.5628C5.28463 28.0786 4.61771 27.0766 4.61771 25.9187C4.61771 24.287 5.94479 22.9599 7.57656 22.9599C9.20833 22.9599 10.5354 24.287 10.5354 25.9187C10.5354 27.5505 9.20833 28.8776 7.57656 28.8776Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M17.3672 52.7651C15.1768 52.7651 13.3927 54.5492 13.3927 56.7396C13.3927 58.9299 15.1768 60.7141 17.3672 60.7141C19.5576 60.7141 21.3417 58.9333 21.3417 56.7396C21.3417 54.5458 19.5609 52.7651 17.3672 52.7651ZM20.0924 57.8906C19.974 58.1784 19.8047 58.4391 19.6049 58.6727C19.0633 59.299 18.2609 59.6984 17.3672 59.6984C15.7354 59.6984 14.4083 58.3714 14.4083 56.7396C14.4083 56.2047 14.5539 55.7003 14.8044 55.2669C14.9771 54.9724 15.1937 54.7083 15.4544 54.4883C15.9724 54.0482 16.6393 53.7807 17.3672 53.7807C18.999 53.7807 20.326 55.1078 20.326 56.7396C20.326 57.1492 20.2448 57.5385 20.0924 57.8906Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M58.9299 21.9443C56.7396 21.9443 54.9555 23.7284 54.9555 25.9187C54.9555 28.1091 56.7396 29.8932 58.9299 29.8932C61.1203 29.8932 62.9044 28.1125 62.9044 25.9187C62.9044 23.725 61.1237 21.9443 58.9299 21.9443ZM60.2164 28.5797C59.9151 28.7286 59.5867 28.8268 59.238 28.8641C59.1365 28.8742 59.0349 28.8776 58.9299 28.8776C57.2982 28.8776 55.9711 27.5505 55.9711 25.9187C55.9711 24.7711 56.6279 23.7792 57.5859 23.2883C57.7654 23.1935 57.9583 23.119 58.1581 23.0648C58.4052 23.0005 58.6625 22.9599 58.9299 22.9599C60.5617 22.9599 61.8888 24.287 61.8888 25.9187C61.8888 27.0901 61.2049 28.1023 60.2164 28.5797Z"
        fill={primary}
        className="fill-primary"
      />
      <path
        d="M49.2984 52.6331C47.1047 52.6331 45.324 54.4172 45.324 56.6076C45.324 58.7979 47.1047 60.582 49.2984 60.582C51.4922 60.582 53.2729 58.7979 53.2729 56.6076C53.2729 54.4172 51.4888 52.6331 49.2984 52.6331ZM49.2984 59.5664C48.425 59.5664 47.6396 59.1839 47.0979 58.5813C46.8711 58.3307 46.6883 58.0396 46.5563 57.7214C46.4175 57.3794 46.3396 57.0037 46.3396 56.6076C46.3396 54.9758 47.6667 53.6487 49.2984 53.6487C50.0263 53.6487 50.6932 53.9161 51.2112 54.3529C51.455 54.5628 51.6615 54.8065 51.8273 55.0807C52.0982 55.5276 52.2573 56.049 52.2573 56.6076C52.2573 58.2393 50.9302 59.5664 49.2984 59.5664Z"
        fill={primary}
        className="fill-primary"
      />
    </svg>
  );
};

export default PersonInCircle;
