import { IconProps } from '../types';
import { useTheme } from 'styled-components';

// --------------------------------------------------------------------------

const BullseyeWithArrow = ({
  height = 50,
  width = 50,
  colorPrimary,
  className,
}: IconProps) => {
  const { midwestColors } = useTheme();
  const primary = colorPrimary ?? midwestColors.identityBrandPrimaryMedium;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={width}
      viewBox="0 0 44 44"
      fill="none"
      className={className}
    >
      <path
        d="M29.8729 29.6404L15.2676 15.2251"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.7187 23.9453C32.8433 23.9453 35.3807 26.4975 35.3807 29.6405C35.3807 32.7835 32.8433 35.3357 29.7187 35.3357C26.594 35.3357 24.0566 32.7835 24.0566 29.6405"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.0322 20.3696C27.8858 20.1175 28.7864 19.9836 29.7183 19.9836C35.0201 19.9836 39.3195 24.3082 39.3195 29.6411C39.3195 34.9739 35.0201 39.2985 29.7183 39.2985C24.4166 39.2985 20.1172 34.9739 20.1172 29.6411C20.1172 28.4516 20.3286 27.3094 20.7202 26.2618"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.1885 17.4936C25.8723 16.7138 27.7439 16.2805 29.7174 16.2805C37.0553 16.2805 42.9993 22.2593 42.9993 29.6402C42.9993 37.0212 37.0553 43 29.7174 43C22.3795 43 16.4355 37.0212 16.4355 29.6402C16.4355 27.4031 16.9837 25.2999 17.947 23.4488"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.856 9.45224L15.2686 15.2262L9.54395 15.9115L1 7.45931L6.72468 6.77398L7.31202 1L15.856 9.45224Z"
        stroke={primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BullseyeWithArrow;
